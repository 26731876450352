<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增标准</el-button>
			</el-row>
			<!-- 技师等级表单 -->
			<el-table border :data="levelData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="biaozhun_id" label="ID"></el-table-column>
				<el-table-column prop="biaozhun_name" label="标准名称"></el-table-column>
				<el-table-column prop="num" label="技师次数"></el-table-column>
				<el-table-column prop="bfb" label="技师百分比"></el-table-column>
				<el-table-column prop="num_1" label="用户次数"></el-table-column>
				<el-table-column prop="bfb_1" label="用户百分比"></el-table-column>
				<el-table-column label="操作" width="160px">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<el-button type="danger" plain size="small" @click="removeLevel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增对话框 -->
			<el-dialog :title="title" width="36%" top="10vh" :visible.sync="addLevelDialog">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="120px" :rules="addlevelRules">
					<el-form-item label="标准名称" prop="biaozhun_name">
						<el-input v-model="addlevelForm.biaozhun_name" maxlength="10" placeholder="请输入标准名称"></el-input>
					</el-form-item>
					<el-form-item label="技师次数" prop="num">
						<el-input v-model.number="addlevelForm.num" placeholder="请输入技师次数"></el-input>
					</el-form-item>
					<el-form-item label="技师百分比" prop="bfb">
						<el-input type="number" v-model="addlevelForm.bfb" placeholder="请输入技师百分比" max="15" @input="numberChange(arguments[0], 15, 'bfb')" @change="numberChange(arguments[0], 15, 'bfb')"></el-input>
					</el-form-item>
					<el-form-item label="用户次数" prop="num_1">
						<el-input v-model.number="addlevelForm.num_1" placeholder="请输入用户次数"></el-input>
					</el-form-item>
					<el-form-item label="用户百分比" prop="bfb_1">
						<el-input type="number" v-model="addlevelForm.bfb_1" placeholder="请输入用户百分比" max="15" @input="numberChange(arguments[0], 15, 'bfb_1')" @change="numberChange(arguments[0], 15, 'bfb_1')"></el-input>
					</el-form-item>
					<el-button type="default" @click="addLevelDialog=false">取消</el-button>
					<el-button type="primary" @click="editNewLevel">确定</el-button>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let _this;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "新增标准",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
					biaozhun_name: '',
					num: '',
					bfb: '',
					num_1: '',
					bfb_1: '',
				},
				addlevelRules: {
					biaozhun_name: [{
						required: true,
						message: "请输入标准名称",
						trigger: "blur"
					}, ],
					num: [{
							required: true,
							message: "请输入技师次数",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					],
					bfb: [{
							required: true,
							message: "请输入技师百分比",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					],
					num_1: [{
							required: true,
							message: "请输入用户次数",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					],
					bfb_1: [{
							required: true,
							message: "请输入用户百分比",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					],
				},
				levelData: []
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				_this = this;
				this.getBiaozhunList();
			}
		},
		methods: {
			getBiaozhunList() {
				var url = 'biaozhun/_list';
				let params = {
					biaozhun_name: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						_this.total = res.total;
						_this.levelData = res.list;
					} else {
						_this.$message.error(res.msg);
					}
				}).catch((err) => {
					_this.$message.error('网络错误');
				});
			},
			//新增
			addLevel() {
				this.title = "新增标准";
				this.addlevelForm = {
					biaozhun_name: '',
					num: '',
					bfb: '',
					num_1: '',
					bfb_1: '',
				};
				this.addLevelDialog = true
			},
			//确认新增
			editNewLevel() {
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null) {
							clearTimeout(timer);
						}
						timer = setTimeout(() => {
							var url = 'biaozhun/addOrUpdate';
							let params = this.addlevelForm;
							_this.fd_post(url, params).then((res) => {
								if(res.status) {
									_this.addLevelDialog = false;
									_this.$refs.addlevelForm.resetFields();
									_this.$message.success('操作成功');
									_this.getBiaozhunList();
								} else {
									_this.$message.error(res.msg);
								}
							}).catch((err) => {
								_this.$message.error('网络错误');
							});
						}, 200);
					}
				});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑标准";
				this.addLevelDialog = true
				for(let k in levelInfo) {
					this.addlevelForm[k] = levelInfo[k]
				}
			},
			//删除
			removeLevel(item) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'biaozhun/_del';
					let params = {
						biaozhun_id: item.biaozhun_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
							this.getBiaozhunList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除')
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getBiaozhunList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getBiaozhunList();
			},
			numberChange(val, maxNum, name) {
				//转换数字类型
				this.addlevelForm[name] = Number(val)
				//重新渲染
				this.$nextTick(() => {
					//比较输入的值和最大值，返回小的
					let num = Math.min(Number(val), maxNum)
					//输入负值的情况下， = 0（可根据实际需求更该）
					if(num < 0) {
						this.addlevelForm[name] = 0
					} else {
						//反之
						this.addlevelForm[name] = num
					}
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.el-row {
		text-align: left;
		margin-bottom: 20px;
	}
</style>